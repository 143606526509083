import type { EnergyTypes } from '~/types/new/energy';

// Interfaces
interface State {
  loading: boolean;
  areaId: number | null;
  period: { start: Date; end: Date } | null;
  energyType: EnergyTypes | null;
  showTemperature: boolean;
  showChildMeters: boolean;
  chartType: string;
}

export const exploreStore = defineStore('exploreStore', {
  state: (): State => ({
    loading: false,
    areaId: null,
    period: null,
    energyType: null,
    showTemperature: false,
    showChildMeters: false,
    chartType: 'line-chart',
  }),
  getters: {
    getLoading: (state) => state.loading,
    getAreaId: (state) => state.areaId,
    getPeriod: (state) => state.period,
    getEnergyType: (state) => state.energyType,
    getMeterId: (state) => {
      const area = areaStore().getArea(state.areaId);
      return area?.meters.find((meter) => meter.meter_type.energy_type === state.energyType)?.id;
    },
    getShowTemperature: (state) => state.showTemperature,
    getShowChildMeters: (state) => state.showChildMeters,
    getChartType: (state) => state.chartType,
  },
  actions: {
    setLoading(loading: State['loading']) {
      this.loading = loading;
    },
    setAreaId(areaId: State['areaId']) {
      this.areaId = areaId;
    },
    setPeriod(period: State['period']) {
      this.period = period;
    },
    setEnergyType(energyType: State['energyType']) {
      this.energyType = energyType;
    },
    setShowTemperature(showTemperature: State['showTemperature']) {
      this.showTemperature = showTemperature;
    },
    setShowChildMeters(showChildMeters: State['showChildMeters']) {
      this.showChildMeters = showChildMeters;
    },
    setChartType(chartType: State['chartType']) {
      this.chartType = chartType;
    },
  },
});
